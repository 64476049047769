import { useThree } from "@react-three/fiber"
import { useControls, button } from 'leva'
import { saveAs } from 'file-saver'
import { OBJExporter } from '../extend/OBJExporter.js'

function DownloadImage() {

  const gl = useThree((state) => state.gl)
  useControls({
    "💾 Save image": button(() => {
      const link = document.createElement('a')
      link.setAttribute('download', 'astasun-embleme.png')
      link.setAttribute('href', gl.domElement.toDataURL('image/png').replace('image/png', 'image/octet-stream'))
      link.click()
    })
  })
}

function DownloadOBJ({ object }) {

  useControls({
    "💾 Save OBJ": button(() => {
      var exporter = new OBJExporter()
      var result = exporter.parse(object.current)
      var blob = new Blob([result], {type: "text/plain"})
      saveAs(blob, 'astrasun-embleme.obj')
    })
  })
}

export { DownloadImage, DownloadOBJ }