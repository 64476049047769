import React from "react"
import { Resizer } from 'postprocessing'
import { EffectComposer, DepthOfField } from "@react-three/postprocessing"

function Effects({ Depth, focusDistance, focalLength, bokehScale }) {

  if (Depth) {
    return (
      <EffectComposer width={Resizer.AUTO_SIZE} height={Resizer.AUTO_SIZE}>
        <DepthOfField focusDistance={focusDistance} focalLength={focalLength} bokehScale={bokehScale} />
      </EffectComposer>
    )
  }
}

export default Effects