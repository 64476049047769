import * as THREE from 'three'

function RoundedCornerLine(points, radius, smoothness, closed) {

  radius = radius !== undefined ? radius : .1;
  smoothness = smoothness !== undefined ? Math.floor(smoothness) : 3;
  closed = closed !== undefined ? closed : false;

  let geometry = new THREE.BufferGeometry();

  if (points === undefined) {
    console.log("RoundedCornerLine: 'points' is undefined");
    return geometry;
  }
  if (points.length < 3) {
    console.log("RoundedCornerLine: 'points' has insufficient length (should be equal or greater than 3)");
    return geometry.setFromPoints(points);
  }

  // minimal segment
  let minVector = new THREE.Vector3();
  let minLength = minVector.subVectors(points[0], points[1]).length();
  for (let i = 1; i < points.length - 1; i++) {
    minLength = Math.min(minLength, minVector.subVectors(points[i], points[i + 1]).length());
  }
  if (closed) {
    minLength = Math.min(minLength, minVector.subVectors(points[points.length - 1], points[0]).length());
  }

  radius = radius > minLength * .5 ? minLength * .5 : radius; // radius can't be greater than a half of a minimal segment

  let startIndex = 1;
  let endIndex = points.length - 2;
  if (closed) {
    startIndex = 0;
    endIndex = points.length - 1;
  }

  let curvePath = new THREE.CurvePath();

  for (let i = startIndex; i <= endIndex; i++) {

    let iStart = i - 1 < 0 ? points.length - 1 : i - 1;
    let iMid = i;
    let iEnd = i + 1 > points.length - 1 ? 0 : i + 1;
    let pStart = points[iStart];
    let pMid = points[iMid];
    let pEnd = points[iEnd];

    // key points
    let keyStart = new THREE.Vector3().subVectors(pStart, pMid).normalize();
    let keyMid = pMid;
    let keyEnd = new THREE.Vector3().subVectors(pEnd, pMid).normalize();

    let halfAngle = keyStart.angleTo(keyEnd) * .5;

    let keyLength = radius / Math.tan(halfAngle);

    keyStart.multiplyScalar(keyLength).add(keyMid);
    keyEnd.multiplyScalar(keyLength).add(keyMid);

    curvePath.add(new THREE.QuadraticBezierCurve3(keyStart, keyMid, keyEnd));

  }

  let curvePoints = curvePath.getPoints(smoothness);

  let fullPoints = [];
  if (!closed) {
    fullPoints.push(points[0])
  }
  fullPoints = fullPoints.concat(curvePoints);
  if (!closed) {
    fullPoints.push(points[points.length - 1])
  } else {
    fullPoints.push(fullPoints[0].clone());
  }

  return new THREE.CatmullRomCurve3(fullPoints);

}

export default RoundedCornerLine