import React, { useRef } from "react"
import * as THREE from 'three'
import { Canvas } from "@react-three/fiber"
import { PerspectiveCamera, OrthographicCamera, OrbitControls } from '@react-three/drei'
import { useControls, folder, button } from 'leva'
import { LayerMaterial, Base, Depth, Fresnel } from 'lamina'

import RoundedCornerLine from '../components/roundedCornerLine.js'
import Effects from '../components/effects.js'
import { DownloadImage, DownloadOBJ } from '../components/download.js'

import "../style/main.scss"


function Ribbon({ points, ...props }) {

  const RibbonRef = useRef()

  const details = {
    wireframe: 200,
    renderer: 1200
  }

  var smoothness = 50
  var curve = RoundedCornerLine(points, props.Radius, smoothness, true)

  const extrudeSettings = {
    steps: props.Guides ? details.wireframe : details.renderer,
    bevelEnabled: false,
    extrudePath: curve
  }

  const square = new THREE.Shape()
  square.moveTo(props.Width/2, props.Thickness/2)
  square.lineTo(props.Width/2, (props.Thickness/2)*-1)
  square.lineTo((props.Width/2)*-1, (props.Thickness/2)*-1)
  square.lineTo((props.Width/2)*-1, props.Thickness/2)

  return (
    <>
      <DownloadOBJ object={RibbonRef} />
      <mesh ref={RibbonRef}>
        <extrudeGeometry name="geometry" args={[square, extrudeSettings]} />
        <LayerMaterial wireframe={props.Guides} lighting="phong" transmission={1}>
          <Base color={props.colorEmbleme} alpha={1} mode="normal" />
          <Depth colorA={props.gradientColor} colorB={props.colorEmbleme} alpha={props.gradientIntensity} mode="normal" near={props.gradientSize[0]} far={props.gradientSize[1]} origin={[props.gradientPosition[0], props.gradientPosition[1], props.gradientPosition[2]]}  />
          <Fresnel mode="softlight" color={"#FFF"} intensity={props.Fresnel} power={2} bias={0} />
        </LayerMaterial>
      </mesh>
    </>
  )
}

function Grid({ points, ...props }) {

  var baseLine = new THREE.BufferGeometry().setFromPoints(points)

  if (props.Guides) {
    return (
      <group>
        <ambientLight color={'#FFF'} intensity={1} />
        <mesh visible={props.Grid}>
          <gridHelper args={[200,20]} position={[0,-100,0]} />
          <gridHelper args={[200,20]} rotation-x={Math.PI / 2} position={[0,0,-100]} />
          <gridHelper args={[200,20]} rotation-z={Math.PI / 2} position={[-100,0,0]} />
        </mesh>
        <mesh visible={props.GradientPoint} position={[props.gradientPosition[0], props.gradientPosition[1], props.gradientPosition[2]]}>
          <sphereBufferGeometry args={[4, 10, 10]}/>
          <meshStandardMaterial attach="material" color={props.gradientColor} />
        </mesh>
        {points.map((point, i) => {
          return (
            <mesh position={[point.x, point.y, point.z]}>
              <sphereBufferGeometry args={[2, 10, 10]} />
              <meshStandardMaterial attach="material" color={'#FF0055'} />
            </mesh>
          )
        })}
        <lineLoop geometry={baseLine}>
          <lineBasicMaterial attach="material" color={'#FF0055'} />
        </lineLoop>
      </group>
    )
  }
}

function Background({ colorBackground }) {
  if (colorBackground) {
    return (
      <color attach="background" args={[colorBackground]} />
    )
  }
}


const IndexPage = () => {

  const orbitRef = useRef()
  const canvasRef = useRef()

  const colors = {
    "Sunburn" : "#EA641C",
    "Clear Sun" : "#F9C419",
    "Sunrise" : "#F0D29F",
    "Fresh Green" : "#CCDCA1",
    "Natural Green" : "#ADC278",
    "Vital Green" : "#96B05B",
    "Clean White" : "#FCFEFF",
    "Tech Night" : "#073146"
  }

  const rig = {
    position: [0, 0, 600],
    positionOrto: [300, 200, 300],
    ortoZoom: 2,
    far: 3000,
    near: 0.1,
    fov: 30
  }

  var points = [
    new THREE.Vector3(-20, 80, 0),
    new THREE.Vector3(-80, -80, 40),
    new THREE.Vector3(80, -1.2, 0), // corrected from: (80, 10, 0) for the most continuos shape possible
    new THREE.Vector3(50, -70, -40)
  ]

  const props = useControls({
    Radius: { value: 20, min: 5, max: 20, step: 1 },
    Width: { value: 10, min: 1, max: 20, step: 0.1 },
    Thickness: { value: 2, min: 0, max: 4, step: 0.1 },
    Colors: folder({
      colorEmbleme: { options: colors, label: 'Embleme' },
      colorBackground: { options: colors, label: 'Background', optional: true, disabled: true },
      Fresnel: { value: 1, min: 0, max: 2, step: 0.1 }
    }),
    Gradient: folder({
      gradientColor: { options: colors, label: 'Color' },
      gradientIntensity: { value: 1, min: 0, max: 2, step: 0.1, label: 'Intensity' },
      gradientSize: { value: [0, 100], min: 0, max: 300, step: 1, label: 'Size' },
      gradientPosition: { value: [40, -20, 0], label: 'Position' }
    }),
    Depth: false,
    DOF: folder({
      focusDistance: { value: 0, min: 0, max: 1, step: 0.01, label: 'Distance' },
      focalLength: { value: 0.1, min: 0, max: 1, step: 0.01, label: 'Length' },
      bokehScale: { value: 2, min: 0, max: 5, step: 1, label: 'Bokeh' }
    },
    { render: (get) => get('Depth') }
    ),
    Guides: false,
    Grid: { value: true, render: (get) => get('Guides') },
    GradientPoint: { value: true, render: (get) => get('Guides') },
    "Reset camera angle": button((get) => orbitRef.current.reset() )
  })
  
  return (
    <main>
      <Canvas flat dpr={[1, 2]} gl={{ preserveDrawingBuffer: true }} ref={canvasRef}>
        <PerspectiveCamera makeDefault={!props.Guides} position={rig.position} far={rig.far} near={rig.near} fov={rig.fov} />
        <OrthographicCamera makeDefault={props.Guides} position={rig.positionOrto} far={rig.far} near={rig.near} zoom={rig.ortoZoom} />
        <OrbitControls ref={orbitRef} />
        <DownloadImage />
        <Background {...props} />
        <Ribbon points={points} {...props} />
        <Grid points={points} {...props} />
        <Effects {...props} />
      </Canvas>
    </main>
  )
}

export default IndexPage